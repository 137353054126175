




















































































































import { debounceInput } from '@/shared/helpers'
import { IReport } from '@/shared/model/report.model'
import axios from 'axios'
import Vue from 'vue'

export default Vue.extend({
  name: 'ReportTable',

  props: {
    companyVehicleId: {
      type: String,
      default: undefined,
    },
    vehicleHasValidData: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: {} as any,

      reports: [] as IReport[],
      reportHeaders: [
        {
          text: this.$t('date'),
          value: 'date',
        },
        {
          text: this.$t('report'),
          value: 'type',
        },
        {
          sortable: false,
          text: this.$t('valid'),
          value: 'valid',
          align: 'end',
        },
        {
          sortable: false,
          text: this.$t('actions'),
          value: 'actions',
          align: 'end',
        },
      ],
      reportSearch: '',
      debounce: undefined as number | undefined,
      canCreateReports: false,
    }
  },
  computed: {},
  watch: {
    options: {
      async handler () {
      // this.getAllReportsWithVDataTable()
      },
    },
    reportSearch: {
      async handler () {
      // this.debounce = debounceInput(this.debounce, this.getAllReportsWithVDataTable)
      },
    },
  },
  async created () {
    if (this.companyVehicleId) {
      await axios.get(`/api/reportByCompanyVehicle/${this.companyVehicleId}`).then((result) => {
        this.reports = result.data
      })
    }
    this.processCanCreateReports()
  },

  methods: {
    clickCreateReport () {
      this.$router.push(`/app/reports/new/${this.companyVehicleId}`)
    },
    clickViewReport (id: string) {
      this.$router.push(`/app/reports/${id}`)
    },
    reportIsValid (report: IReport) {
      let valid = true
      if (report.questions) {
        for (const q of report.questions) {
          if (!q.valid) {
            valid = false
          }
        }
      }

      return valid
    },
    processCanCreateReports () {
      axios.get('/api/myselfCanCreateReports').then((result) => {
        if (result.data) {
          this.canCreateReports = result.data.canCreateReports
        }
      })
    },
  },
})
